<template>
	<div class="grid dashboard">
        <!-- <Calendar :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  class="rangeCalendar" id="range" @click="openCalendar(event)" v-model="selectedDates" selectionMode="range" :manualInput="false" :touchUI="true" /> -->
        <div class="col-12 md:col-12 lg:col-12">
            <div class="card" style="float:left; width:100%">
                <div class="col-11 md:col-11 lg:col-11 sm:col-12" style="float:left"> 
                    <span v-if="overallStates">
                        <span v-if="overallStates.quick_links && Object.keys(overallStates.quick_links).length > 0">
                            <span v-for=" (link, key) in overallStates.quick_links" :key="key" >
                                <a :target="link.open_in_new_window ? '_blank':''" :href="link.link">
                                    <Button :icon="link.open_in_new_window ? 'pi pi-external-link': ''" :label="link.name" class="p-button-raised mr-2" :style="link.background_color != '' ? 'background-color :#'+link.background_color : ''" /> 
                                </a>
                            </span> 
                        </span>
                        <h4 v-else>No quick link is saved yet! <span class="link" @click="showQuickLinkForm=true">Click</span> at Cog icon to add new.</h4> 
                    </span> 
                </div>
                <div class="col-1 md:col-1 lg:col-1 sm:col-12" style="float:left">
                    <Button @click="showQuickLinkForm=true;addViewInURL({view: 'add-quicklink', id:0})" label="" icon="pi pi-cog" style="float: right;" class="p-button-rounded p-button-secondary p-button-lg " /> 
                </div>
            </div>
        </div>
        
        <div class="col-12 sm:col-6 md:col-3 lg:col-3"> 
            <div class="card height-90" > 
                <div class="card-header"> 
                    <router-link to="/calendar"> 
                        <h5 class="widget-statistics-heading-h5">To Do List <span v-if="overallStates && overallStates.calendar">({{overallStates.calendar.total_upcoming}})</span></h5> 
                    </router-link>
                    <div>
                        <Button v-if="permissions[50].includes('insert')" v-tooltip.top="''" :label="showBtnLabels ? '' : ''" @click="addViewInURL({view: 'add-calendar', id: selectedUserId});showCalendarForm=true;selectedToDoId=0" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" />   
                        <Button v-if="permissions[50].includes('view')" v-tooltip.top="''" :label="showBtnLabels ? '' : ''" @click="filterToDo=true" icon="pi pi-filter" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" />   
                    </div> 
                </div>  
                <div class="card-header" v-if="filterToDo">
                    <InputText id="searchToDo" @keyup="searchCalendarEvents()" v-model="calendarEventsSearch" placeholder="Search"  type="text" class="full-width mr-2"/>
                    <Button v-if="permissions[50].includes('view')" v-tooltip.top="''" :label="showBtnLabels ? '' : ''" @click="filterToDo=false;closeCalendarEventsSearch()" icon="pi pi-times" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger p-3 mr-2" />   
                </div>
                <ul class="widget-bestsellers todo-tasks-widget">
                    <li v-if="calendarEvents.length" >
                        <div v-for=" (event, key) in calendarEvents" :key="key" class="bestseller-item flex align-items-center p-3 mb-2"> 
                            <a href="javascript:;" @click="openCalendarForm(event.id)">
                                <div>
                                    <strong>{{event.name}}</strong><br/>
                                    <p>{{shortStr(event.description,0,100)}}</p>
                                    <span class="task-schedule">{{formatDateTime(event.start_time)}} <span v-if="event.end_time">| {{formatDateTime(event.end_time)}}</span></span>
                                    <div class="todo-users">  
                                        <AvatarGroup class="mb-0 mt-3 ml-0" v-if="overallStates.calendar.event_users">
                                            <span v-if="overallStates.calendar.event_users[event.id]"> 
                                                <span v-for=" (user, index) in overallStates.calendar.event_users[event.id]" :key="index"  > 
                                                    <Avatar class="event-user-avatar" v-if="index < 5 && user.profile_pic" :image="filesUrl+'users/'+user.profile_pic" size="large" shape="circle"></Avatar>
                                                    <Avatar class="event-user-avatar" v-else-if="index < 5" :image="filesUrl+'defaults/default.png'" size="large" shape="circle"></Avatar>
                                                    <Avatar class="event-user-avatar" v-else-if="index > 4 && index < 6" :label="overallStates.calendar.event_users[event.id].length-(index+1)+'+'" shape="circle" size="large" :style="{'background-color':'#9c27b0', 'color': '#ffffff'}"></Avatar>
                                                </span>
                                            </span> 
                                        </AvatarGroup> 
                                    </div>
                                </div> 
                            </a> 
                        </div>   
                    </li>
                    <li v-else><div><h6 class="text-center pt-5 pb-5">No Record Found!</h6></div></li>
                </ul>  
            </div>
        </div>
        <div class="col-12 sm:col-6 md:col-3 lg:col-3" v-if="!checkUserCoreRole(['Customer']) && checkPermission(5,'insert')">
            <div class="card height-90" v-if="overallStates && overallStates.employees">  
                <div class="card-header"> 
                    <router-link to="/access/manage-users">
                        <h5 class="widget-statistics-heading-h5">Employees ({{overallStates.employees.total}}) </h5> 
                    </router-link>
                    <Button v-if="permissions[5].includes('insert')" v-tooltip.top="''" :label="showBtnLabels ? '' : ''" @click="addViewInURL({view: 'add-user', id: selectedUserId}); showUserForm=true;" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" />   
                </div>  
                <!-- <Divider /> -->
                <div class="card-body">
                    <ul class="widget-list dashboard-employee-list" v-if="overallStates.employees.employees">
                        <li v-for=" (employee, key) in overallStates.employees.employees" :key="key" class="flex align-items-center py-3">
                            <div class="person-item pointer flex align-items-center" @click="openUserForm( employee.id,employee.user_type )">
                                <img  v-if="employee.profile_pic" :alt="employee.name" :src="filesUrl+'users/'+employee.profile_pic" class="tableProfileImg mr-2" />
                                <img  v-if="!employee.profile_pic" :alt="employee.name" :src="filesUrl+'defaults/default.png'" class="tableProfileImg mr-2" /> 
                                <div :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
                                    <div><strong>{{employee.name}}</strong></div>
                                    <small class="muted-text">{{employee.email}}</small>
                                </div>
                            </div>
                            <span v-if="employee.designation_name" class="person-tag indigo-bgcolor p-1 fs-small" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">{{employee.designation_name}}</span> 
                        </li>  
                    </ul>
                    <div v-else><h6 class="text-center pt-5 pb-5">No Employee Found! Create One</h6></div>
                </div> 
            </div>
        </div>  
        <div class="col-12 sm:col-6 md:col-3 lg:col-3"> 
            <div class="card overview-box flex flex-column pt-2 height-90"> 
                <div class="flex justify-content-between mt-3 flex-wrap"> 
                    <div class="digital-clock-widget" >
                        <DigitalClock :fancy="true"/>
                    </div> 
                    <Divider />
                    <div class="widget-statistis-circle mb-3" :style="'background-color:'+  $appState.sideBarColor "  v-if="permissions[40] && overallStates && overallStates.orders">
                        <router-link to="/orders/manage-orders">
                            <h4>Orders</h4>
                            <h3>{{overallStates.orders.this_month}}/{{overallStates.orders.total}}</h3>
                        </router-link>
                    </div>  
                    <div class="widget-statistis-circle mb-3"  :style="'background-color:'+  $appState.sideBarColor "  v-if="permissions[43] && overallStates && overallStates.invoices">
                        <router-link to="/orders/manage-orders">
                            <h4>Invoices</h4>
                            <h3>{{overallStates.invoices.this_month}}/{{overallStates.invoices.total}}</h3>
                        </router-link>
                    </div> 
                    <div class="widget-statistis-circle mb-3"  :style="'background-color:'+  $appState.sideBarColor "  v-if="permissions[5] && overallStates && overallStates.users">
                        <router-link to="/access/manage-users">
                            <h4>Users</h4>
                            <h3>{{overallStates.users.this_month}}/{{overallStates.users.total}}</h3>
                        </router-link>
                    </div>   
                    <!-- <div class="widget-statistis-circle mb-3" :style="'background-color:'+  $appState.sideBarColor "  v-if="permissions[31] && overallStates && overallStates.customers">
                        <router-link to="/customers/manage-customers">
                            <h4>Customers</h4>
                            <h3>{{overallStates.customers.this_month}}/{{overallStates.customers.total}}</h3>
                        </router-link>
                    </div>   --> 
                    <div class="widget-statistis-circle mb-3"  :style="'background-color:'+  $appState.sideBarColor "  v-if="!checkUserCoreRole(['Customer']) && checkPermission(38,'insert') && overallStates && overallStates.vehicles">
                        <router-link to="/vehicles/manage-vehicles">
                            <h4>Vehicles</h4>
                            <h3>{{overallStates.vehicles.this_month}}/{{overallStates.vehicles.total}}</h3>
                        </router-link>
                    </div>  
                    <div class="widget-statistis-circle mb-3" :style="'background-color:'+  $appState.sideBarColor "  v-if="!checkUserCoreRole(['Customer']) && checkPermission(16,'view') && overallStates && overallStates.companies">
                        <router-link to="/companies/manage-companies">
                            <h4>Companies</h4> 
                            <h3>{{overallStates.companies.this_month}}/{{overallStates.companies.total}}</h3>
                        </router-link>
                    </div>  
                </div>  
            </div> 
        </div> 
        <div class="col-12 sm:col-6 md:col-3 lg:col-3 widget">
            <div class="card mb-0" v-if="checkPermission(40,'view') && overallStates && overallStates.payments"> 
                <div class="card-header"> 
                    <div class="credited-amount">
                        <span v-if="overallStates.payments.this_month.total_credited">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((overallStates.payments.this_month.total_credited)).toFixed(priceRounding)}}</span>
                        <span v-else>{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((0)).toFixed(priceRounding)}}</span>
                        <span class="dashboard-payment-text">Amount Credited</span>
                        <span class="dashboard-total-payment-text" v-if="overallStates.payments.total.total_credited">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((overallStates.payments.total.total_credited)).toFixed(priceRounding)}}</span>
                    </div> 
                    <div class="debited-amount">
                        <span v-if="overallStates.payments.this_month.total_debited">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((overallStates.payments.this_month.total_debited)).toFixed(priceRounding)}} </span>
                        <span v-else>{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((0)).toFixed(priceRounding)}}</span>
                        <span class="dashboard-payment-text">Amount Debited</span>
                        <span class="dashboard-total-payment-text" v-if="overallStates.payments.total.total_debited">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((overallStates.payments.total.total_debited)).toFixed(priceRounding)}}</span>
                    </div>  
                </div> 
            </div>
            <Divider />
            <div class="card mb-0" v-if="checkPermission(40,'view')  && overallStates"> 
                <div class="card-header" v-if="overallStates.orders">
                    <router-link to="/orders/manage-orders">
                        <h5 class="widget-statistics-heading-h5">Orders ({{overallStates.orders.total}}) </h5> 
                    </router-link>
                    <Button v-if="checkPermission(40,'insert') " v-tooltip.top="''" :label="showBtnLabels ? '' : ''" @click="showOrderForm=true" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                </div> 
                <ul class="widget-activity two-column-ul">
                    <li>
                        <router-link to="/orders/manage-orders?status=new">
                            <div class="activity-item flex flex-column">
                                <div class="activity-title mb-1">New <div style="float:right"><h5 class="mb-0"  v-if="overallStates.orders">{{overallStates.orders.total_new}}</h5></div></div> 
                                <ProgressBar  v-if="overallStates.orders" :value="((overallStates.orders.total_new/overallStates.orders.total)*100).toFixed()" :showValue="true"></ProgressBar>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/orders/manage-orders?status=Confirmed"> 
                            <div class="activity-item flex flex-column">
                                <div class="activity-title mb-1">Confirmed <div style="float:right"><h5 class="mb-0"  v-if="overallStates.orders">{{overallStates.orders.total_confirmed}}</h5></div></div> 
                                <ProgressBar  v-if="overallStates.orders" :value="((overallStates.orders.total_confirmed/overallStates.orders.total)*100).toFixed()" :showValue="true"></ProgressBar>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/orders/manage-orders?status=Canceled"> 
                            <div class="activity-item flex flex-column">
                                <div class="activity-title mb-1">Canceled <div style="float:right"><h5 class="mb-0"  v-if="overallStates.orders">{{overallStates.orders.total_canceled}}</h5></div></div> 
                                <ProgressBar  v-if="overallStates.orders" :value="((overallStates.orders.total_canceled/overallStates.orders.total)*100).toFixed()" :showValue="true"></ProgressBar>
                            </div>
                        </router-link>    
                    </li>
                    <li>
                        <router-link to="/orders/manage-orders?status=Delivered">
                            <div class="activity-item flex flex-column">
                                <div class="activity-title mb-1">Delivered <div style="float:right"><h5 class="mb-0"  v-if="overallStates.orders">{{overallStates.orders.total_delivered}}</h5></div></div> 
                                <ProgressBar  v-if="overallStates.orders" :value="((overallStates.orders.total_delivered/overallStates.orders.total)*100).toFixed()" :showValue="true"></ProgressBar>
                            </div>
                        </router-link>
                    </li>
                </ul> 
            </div>
            <Divider />
            <div class="card mb-0" v-if="overallStates && overallStates.invoices && checkPermission(43,'view') ">
                <div class="card-header">
                    <h5 class="widget-statistics-heading-h5">Invoices ({{overallStates.invoices.total}}) </h5>  
                </div> 
                <ul class="widget-activity two-column-ul">
                    <li>
                        <div class="activity-item flex flex-column">
                            <div class="activity-title">Pending <div style="float:right"><h5 class="mb-0"  v-if="overallStates.invoices">{{overallStates.invoices.total_pending}}</h5></div></div> 
                            <ProgressBar  v-if="overallStates.invoices" :value="((overallStates.invoices.total_pending/overallStates.invoices.total)*100).toFixed()" :showValue="true"></ProgressBar>
                         </div>
                    </li>
                     <li>
                        <div class="activity-item flex flex-column">
                            <div class="activity-title">Confirmed <div style="float:right"><h5 class="mb-0"  v-if="overallStates.invoices">{{overallStates.invoices.total_confirmed}}</h5></div></div> 
                            <ProgressBar  v-if="overallStates.invoices" :value="((overallStates.invoices.total_confirmed/overallStates.invoices.total)*100).toFixed()" :showValue="true"></ProgressBar>
                        </div>
                    </li>
                     <li>
                        <div class="activity-item flex flex-column">
                            <div class="activity-title">Paid <div style="float:right"><h5 class="mb-0"  v-if="overallStates.invoices">{{overallStates.invoices.total_paid}}</h5></div></div> 
                            <ProgressBar  v-if="overallStates.invoices" :value="((overallStates.invoices.total_paid/overallStates.invoices.total)*100).toFixed()" :showValue="true"></ProgressBar>
                        </div>
                    </li>
                     <li>
                        <div class="activity-item flex flex-column">
                            <div class="activity-title">Canceled <div style="float:right"><h5 class="mb-0"  v-if="overallStates.invoices">{{overallStates.invoices.total_canceled}}</h5></div></div> 
                            <ProgressBar  v-if="overallStates.invoices" :value="((overallStates.invoices.total_canceled/overallStates.invoices.total)*100).toFixed()" :showValue="true"></ProgressBar>
                        </div>
                    </li>
                </ul>
            </div>
        </div> 
        <div class="col-12 sm:col-12 md:col-6 lg:col-6">
            <div class="card">
                <div class="card-header">
                    <h5>Orders Graph</h5>
                </div> 
                <div v-if="checkPermission(40,'view')  && overallStates && ordersChart" > 
                    <Chart ref="monthly" type="bar" :data="ordersChart" :options="chartMonthlyOptions" responsive="true" :height="130"></Chart>
                </div>
            </div>
        </div>
        <div class="col-12 sm:col-12 lg:col-6">
			<div class="card">
				<div class="card-header">
					<h5>Payments Graph</h5> 
				</div>
                <div v-if="checkPermission(40,'view')  && overallStates && ordersChart" >
                    <Chart type="line" :data="paymentsChart" :options="ordersOptions" responsive="true" :height="130"></Chart>
                </div> 
			</div>
		</div>   
	</div>
    <Toast />
    <ConfirmDialog group="dialog" />
    <UserProfileView v-on:UserProfileClose="closeUserProfileView"  v-if="showUserProfileView  && (permissions[5].includes('insert') || permissions[5].includes('update'))" :userType="userType" :recordId="selectedUserId" :showForm="true" /> 
    <QuickLinksForm v-on:formClose="closeForm();removeViewFromURL('add-quicklink');" v-if="showQuickLinkForm  && (permissions[58].includes('insert') || permissions[58].includes('update'))" :recordId="0" :showForm="true" /> 
    <!-- <CustomerForm v-on:CustomerformClose="closeForm" v-if="showCustomerForm  && (permissions[31].includes('insert') || permissions[31].includes('update'))" :recordId="0" :showForm="true" />  -->
    <CalendarForm :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  v-on:formClose="closeForm(); removeViewFromURL('add-calendar');" v-if="showCalendarForm  && (permissions[50].includes('insert') || permissions[50].includes('update'))" :recordId="selectedToDoId" :showForm="true" /> 
	<OrdersForm v-on:closeOrderFrom="closeForm" v-if="showOrderForm  && (permissions[40].includes('insert') || permissions[40].includes('update'))" :recordId="0" :showForm="true" /> 
    <UserForm v-on:formClose="closeForm(); removeViewFromURL('add-user');" v-if="showUserForm  && (permissions[5].includes('insert') || permissions[5].includes('update'))" :recordId="0" :showForm="true" /> 
</template>

<script> 
import QuickLinksForm from '../../components/QuickLinks/QuickLinksForm.vue';
import CalendarForm from '../../components/CalendarForm.vue';
import DigitalClock  from "../../components/DigitalClock.vue";
// import CustomerForm from '../../components/Customers/CustomerForm.vue';
import UserProfileView from '../../components/AccessManagement/UserProfileView.vue';
import OrdersForm from '../../components/Orders/OrdersForm.vue';
import UserForm from '../../components/AccessManagement/UserForm.vue';
export default {
	data() {
		return {
			overallStates: [],
            loading:false,
            filterToDo:false,
            CompaniesMenuItems: [{label: 'Date Range', icon: 'pi pi-fw pi-calendar'} ],
            selectedDates: '',
            showUserProfileView:false,
            showUserForm:false,
            showOrderForm:false,
            showCalendarForm:false,
            showCustomerForm:false,
            showQuickLinkForm:false,
            selectedUserId:0,
            chartMonthlyOptions:'',
            calendarEventsSearch: '',
            userType:'',
            calendarEvents:[],
            priceRounding:2,


            pinkColor: '#EC407A' ,
            purpleColor: '#AB47BC' ,
            deeppurpleColor: '#7E57C2' ,
            indigoColor: '#5C6BC0' ,
            blueColor: '#42A5F5' ,
            lightblueColor: '#29B6F6' ,
            cyanColor: '#00ACC1' ,
            tealColor: '#26A69A' ,
            greenColor: '#66BB6A' ,
            lightgreenColor: '#9CCC65' ,
            limeColor: '#D4E157' ,
            yellowColor: 'FFEE58' ,
            amberColor: '#FFCA28' ,
            orangeColor: '#FFA726' ,
            deeporangeColor: '#FF7043' ,
            brownColor: '#8D6E63' ,
            ordersChart: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: []
            },
            paymentsChart: {
				labels:  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				datasets: []
			},
			ordersOptions: null
		}
	}, 
	created() { 
	},
    components:{
        UserProfileView,
        UserForm,
        OrdersForm, 
        DigitalClock,
        CalendarForm,
        QuickLinksForm
    },
	mounted() {
        this.priceRounding = this.userData['company_price_rounding'] ? this.userData['company_price_rounding'] : 2,
        this.getOverallStates();  
        if(this.$route.query.view){
			this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
			if(Object.keys(this.urlParams).length){
				this.urlParams.forEach( (parameter) => {
					if(parameter.view == 'add-quicklink'){
						this.selectedRecordId = parameter.id;
						this.showQuickLinkForm = true;
					} 
                    else if(parameter.view == 'add-calendar'){
						this.selectedToDoId = parameter.id;
						this.showCalendarForm = true;
					} 
                    else if(parameter.view == 'add-user'){
						this.selectedUserId = parameter.id;
						this.showUserForm = true;
					} 
                    else if(parameter.view == 'user-profile-view'){ 
                        this.selectedUserId=parameter.id; 
                        this.userType = parameter.userType;
                        this.showUserProfileView=true;
					}  
				})
			}
		}
	},
	watch: { 
	},
	methods: {
        closeUserProfileView(){ 
            this.showUserProfileView = false; 
            this.removeViewFromURL('user-profile-view');
        }, 
        openCalendarForm(eventId){ 
            this.selectedToDoId=0;
            this.showCalendarForm=true;
            if(eventId > 0){
                this.selectedToDoId=eventId;  
            } 
            this.addViewInURL({view: 'add-calendar', id:this.selectedToDoId}); 
        },
        openUserForm(employeeId,userType){ 
            if(employeeId > 0){
                this.selectedUserId=employeeId; 
                this.userType = userType;
                this.showUserProfileView=true;
                this.addViewInURL({view: 'user-profile-view', id:this.selectedUserId, userType: this.userType}); 
            }
        },
        closeForm(){ 
            this.showUserForm = false;
            this.showUserProfileView = false;
            this.showOrderForm = false;
            this.showCalendarForm = false;
            this.showQuickLinkForm = false;
            this.showCustomerForm = false;
            this.getOverallStates();
        },
        closeCalendarEventsSearch(){
            this.calendarEventsSearch = '';
            this.calendarEvents = this.overallStates.calendar.upcoming_events;
        },
        searchCalendarEvents(){ 
            var Vthis = this;
            this.calendarEvents = this.overallStates.calendar.upcoming_events;
            if(this.calendarEventsSearch.length > 1){
                this.calendarEvents = this.calendarEvents.filter(event => {
                    return event.name.toLowerCase().indexOf(Vthis.calendarEventsSearch.toLowerCase()) > -1 || 
                    event.description.toLowerCase().indexOf(Vthis.calendarEventsSearch.toLowerCase()) > -1 ||
                    Vthis.formatDateTime(event.start_time).toLowerCase().indexOf(Vthis.calendarEventsSearch.toLowerCase()) > -1 ||
                    Vthis.formatDateTime(event.end_time).toLowerCase().indexOf(Vthis.calendarEventsSearch.toLowerCase()) > -1 
                });
            } 
        },
        getOverallStates(){
            var Vthis = this;
            this.loading = true;
            this.axios.get('getOverallStates')
            .then( (response) => {   
                let responseStatus = this.printResponseResult(response,false); 
                if(responseStatus == 200){ 
                    this.overallStates = response.data;  
                    this.calendarEvents = this.overallStates.calendar.upcoming_events;
                    if(response.data.order_chart_data.length){ 
                        Vthis.ordersChart.datasets = response.data.order_chart_data; 
                    }
                    if(response.data.payments_chart_data.length){ 
                        Vthis.paymentsChart.datasets = response.data.payments_chart_data; 
                    }
                    this.loading = false; 
                }
            })
            .catch((error) => {   
                this.printResponseResult(error);
            })
            .finally(() => { 
            })  
        }
		 
	},
	computed: { 
	}
}
</script>

<style lang="scss" scoped>

</style>
