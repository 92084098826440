<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText :disabled="viewOnlyCalendarForm" id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>  
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Start Date Time</label>
                                    <Calendar :disabled="viewOnlyCalendarForm"  :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  @date-select="startDateTimeSelected($event)" :showButtonBar="true" id="start_time" v-model="form.start_time" :showTime="true" :stepMinute="5" :showSeconds="false" class="full-width"/> 
                                    <HasError class="p-error" :form="form" field="start_time" /> 
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>End Date Time </label>
                                    <Calendar  :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  :minDate="minDateTime" @date-select="endDateTimeSelected($event)"  :showButtonBar="true" :disabled="form.start_time == '' || viewOnlyCalendarForm ? true:false" id="end_time" v-model="form.end_time" :stepMinute="5" :showTime="true" :showSeconds="false" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="end_time" /> 
                                </div> 
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Status</label>
                                    <Dropdown :disabled="viewOnlyCalendarForm"  v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left" > 
                                    <label>Description</label>
                                    <Textarea :disabled="viewOnlyCalendarForm"  placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>   
                            </div>  
                        </div>  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6" v-if="!checkUserCoreRole(['Customer']) && checkPermission(5,'dropdown') && !viewOnlyCalendarForm" >
                            <div>  
                                <Panel class="mb-3" header="Users" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">   
                                        <div class="field-checkbox">
                                            <Checkbox @click="selectAllUsers()" value="true" v-model="selectAllUsersCheckBox" :binary="true" />
                                            <label >Select All</label>
                                        </div>
                                        <HasError class="p-error" :form="form" field="users" /> 
                                        <Listbox v-model="form.users" filterPlaceholder="Select Users" checkbox="true" :multiple="true" :filter="true" :showClear="true" :options="users" optionLabel="name"  optionValue="id" class="full-width" />
                                    </div> 
                                </Panel>  
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start v-if="!viewOnlyCalendarForm">
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button v-if="!viewOnlyCalendarForm" type="button" label="Delete" icon="pi pi-trash" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="deleteRecord()"/>
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    <Toast />
    <ConfirmDialog group="dialogDelete" />
</template>
<script> 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    company_id: 0, 
                    name: '',   
                    users:[],
                    start_time: 0,   
                    end_time: 0,    
                    status:1, 
                    description: '',    
                }),   
                viewOnlyCalendarForm:false,
                users:[],
                CompanyLoading:false,
                minDateTime:false, 
                returnMinDateTime:'',
                module_name:'Calendar Events',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                selectAllUsersCheckBox:false,
                companies:[],  
                user:[],
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedDate: {
                type : String
            }
        },
		created() {   
		},
        computed: { 
        },
		mounted() {  
            this.user = JSON.parse(localStorage.getItem('user'));  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
            
            if(this.selectedDate != '' && this.selectedDate){
                this.form.start_time = new Date(this.selectedDate); 
            }
          
		},
		methods: {    
            startDateTimeSelected($event){
                this.form.end_time = this.returnMinDateTime = '';
                var $selectedDate = $event; 
                this.minDateTime = new Date($selectedDate); 
                this.returnMinDateTime = new Date($selectedDate); 
            },
            endDateTimeSelected($event){
                this.returnMinDateTime = '';
                var $selectedDate = $event;  
                this.returnMinDateTime = new Date($selectedDate); 
            },  
            selectAllUsers(){ 
               if(!this.selectAllUsersCheckBox){
                   this.users.forEach((user) => {
                       this.form.users.push(user.id);
                   });
               }
               else{
                   this.form.users = [];
               }
            },
            getAllRequestData(){   
                this.$axios.all([this.getUsers()])   
                .then(() => { 
                    this.updateRecord();
                })
                .then(() => {  
                    this.getCompanies();    
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
            async getCompanies(){ 
                var Vthis = this;
                Vthis.CompanyLoading = true;
                await this.axios.post('getAllCompanies')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.CompanyLoading = false;
                })  
            }, 
            async getUsers(){
                var Vthis = this;  
                await Vthis.axios.post('getAllUsers')
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        // Vthis.users = response.data;  
                        var users = response.data;  
                        if(users){
                            users = users.forEach( (rec) => {
                                if(rec.id != Vthis.user.id)
                                Vthis.users.push(rec);
                            }) 
                        }  
                    }

                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                })
            },
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveCalendarEvent', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(!this.recId){
                            // this.getRecords();
                        }
                        // this.getRecords();
                        if(this.saveAndClose){   
                            setTimeout( () => {this.closeForm();},1000);
                            this.saveAndClose = false;
                            // this.closeForm();
                        }   
                        Vthis.showMessage('Saved successfully','success',); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialogDelete',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                      
                        let DeleteAbleRecords = []; 
                        DeleteAbleRecords.push(this.recId);
                            
                        // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                        // filterdRecords.forEach((data) => {
                        //     DeleteAbleRecords.push(data.id);
                        // })  

                        Vthis.axios.post('deleteCalendarEvent',{id:DeleteAbleRecords})
                        .then( (response) => {  
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){   
                                Vthis.showMessage('Record deleted successfully','success'); 
                                Vthis.closeForm();
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            }, 
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllCalendarEvents',{id:this.recId})
                    .then( (response) => {  
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            this.viewOnlyCalendarForm = false; 
                            if(this.userData.id != response.data[0].user_id){
                                this.viewOnlyCalendarForm = true;
                            }
                            // viewOnlyCalendarForm
                            var selectedUsers =   response.data[0].calendar_event_users;
                            response.data[0].calendar_event_users = [];
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]); 
                                if(selectedUsers.length == this.users.length){
                                    Vthis.selectAllUsers = true;
                                }
                                Vthis.form.users = selectedUsers.split(",").map(Number); 
                                // Vthis.form.users = Vthis.form.users.map(Number); 
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
		},
        watch : {
             
        }
}
</script>
 
